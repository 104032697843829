<template>
    <b-modal
      id="pending-order-detail-modal"
      title=""
      centered
      hide-footer
      size="lg"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >

      <div class="container">
            <table class="table mt-2" v-if="order">
              <tbody>
                <tr>
                  <td><strong>Patient</strong></td>
                  <td>{{ order.patient_data.full_name }}</td>
                </tr>
                <tr>
                  <td><strong>Date</strong></td>
                  <td >{{ order.created_at }}</td>
                </tr>
                <tr>
                  <td><strong>Order Info</strong></td>
                  <td>{{ order.order_number }}</td>
                </tr>
                <tr>
                  <td><strong>Status</strong></td>
                  <td> 
                    <span v-if="!order.is_paid">Pending</span>
                    <span v-else>Paid</span>
                  </td>
                 

                </tr>
              </tbody>
            </table>

            <b-card-body v-if="order">
            <b-table
              stacked="md"
              :fields="fieldsOrderItems"
              :items="order.order_items"
              thead-class="tableHeadColor"
              small
              rounded
            >
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlack">{{
                    data.label
                  }}</span>
                </div>
              </template>
              <template #cell(is_paid)="row">
              <div class="py-50">
                <b-badge
                  v-if="!row.item.is_paid"
                  pill
                  class="font-weight-bolder m-0"
                  variant="danger"
                  style="font-size: 0.8rem"
                >
                  Pending
                </b-badge>
                <b-badge
                  v-else
                  pill
                  class="font-weight-bolder m-0"
                  variant="danger"
                  style="font-size: 0.8rem"
                >
                  ""
                </b-badge>
              </div>
          </template>

          <template #cell(Item)="row">
            <div class="py-50">{{ row.item.service_name }}</div>
          </template>
          <template #cell(amount)="row">
            <div class="py-50">${{ row.item.amount }}</div>
          </template>
            </b-table>
          </b-card-body>

        </div>
     
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";

  
  export default {
    components: {
    },
    mixins: [util],
    props: {
      order: Object,
    },
    data() {
      return {
        fieldsOrderItems: [
        { key: "Item", label: "Item"},
        { key: "amount", label: "Amount"},
      ],
      }
    },
    async mounted() {
    },
    methods: {
      ...mapActions({
      }),
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    },
  };
  </script>
  
  <style></style>