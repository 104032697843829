<template>
  <div style="height: 100%">
    <b-row class="h-100">
      <b-col md="12" class="h-100 mb-3">
        <!-- <b-card no-body>
          <b-card-header>
            <div>
              <h4 class="font-weight-bolder text-dark m-0 p-0">
                Pending Invoice
              </h4>
            </div>
            <div>
              <span class="font-weight-bolder text-dark m-0 p-0">
                <feather-icon
                  icon="ListIcon"
                  class="text-colorBlue mr-50"
                  size="26"
                />
                Filter
              </span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-table
              stacked="md"
              :fields="fieldsPendingInvoice"
              :items="pendingInvoice"
              thead-class="tableHeadColor"
              small
              rounded
            >
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlack">{{
                    data.label
                  }}</span>
                </div>
              </template>
              <template #cell(Status)="row">
                <div class="py-50">
                  <b-badge
                    v-if="row.item.Status === 'Pending'"
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    {{ row.item.Status ? row.item.Status : "" }}
                  </b-badge>
                  <b-badge
                    v-if="row.item.Status === 'Paid'"
                    pill
                    class="font-weight-bolder m-0"
                    variant="success"
                    style="font-size: 0.8rem"
                  >
                    {{ row.item.Status ? row.item.Status : "" }}
                  </b-badge>
                </div>
              </template>

              <template #cell(manage)="">
                <div class="">
                  <b-button pill size="sm" variant="colorBlue">
                    Pay Now
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card> -->

        <b-card no-body>
          <b-card-header>
            <div>
              <h4 class="font-weight-bolder text-dark m-0 p-0">Order Status</h4>
            </div>
            <!-- <div>
              <span class="font-weight-bolder text-dark m-0 p-0">
                <feather-icon
                  icon="ListIcon"
                  class="text-colorBlue mr-50"
                  size="26"
                />
                Filter
              </span>
            </div> -->
          </b-card-header>

          <!-- Pending Orders  -->
          <b-card-body>
            <b-table
              stacked="md"
              :fields="fieldsPendingOrders"
              :items="PendingOrders"
              details-td-class="m-0 p-0"
              thead-class="tableHeadColor"
              small
              :per-page="0"
              rounded
            >
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlack">{{
                    data.label
                  }}</span>
                </div>
              </template>

              <template #cell(patient_data)="row">
                <div class="py-50">{{ row.item.patient_data.full_name }}</div>
              </template>
              <template #cell(amount)="row">
                <div class="py-50">${{ row.item.amount }}</div>
              </template>

              <template #cell(is_paid)="row">
                <div class="py-50">
                  <b-badge
                    v-if="!row.item.is_paid"
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    Pending
                  </b-badge>
                  <b-badge
                    v-else
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    ""
                  </b-badge>
                </div>
              </template>
              <template #cell(manage)="row">
                <div class="">
                  <b-button
                    pill
                    size="sm"
                    variant="colorGreen"
                    @click="viewPendingOrder(row.item)"
                  >
                    View Details
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    variant="info"
                    class="mx-1"
                    @click="Payment(row.item)"
                  >
                    Pay
                  </b-button>
                </div>
              </template>
            </b-table>

            <b-pagination
              size="md"
              :total-rows="totalPendingItems"
              v-model="pendingOrdersCurrentPage"
              :per-page="pendingOrdersPerPage"
            ></b-pagination>

            <h6>
              <b>Total Rows: {{ totalPendingItems }}</b>
            </h6>
          </b-card-body>
        </b-card>
        <!-- Pending Orders end here  -->

        <!-- Previous / Paid Orders  -->
        <b-card no-body>
          <b-card-header>
            <div>
              <h4 class="font-weight-bolder text-dark m-0 p-0">
                Previous Order History
              </h4>
            </div>
            <!-- <div>
                <span class="font-weight-bolder text-dark m-0 p-0">
                  <feather-icon
                    icon="ListIcon"
                    class="text-colorBlue mr-50"
                    size="26"
                  />
                  Filter
                </span>
              </div> -->
          </b-card-header>
          <b-card-body>
            <b-table
              stacked="md"
              :fields="fieldsPaidOrders"
              :items="PaidOrders"
              thead-class="tableHeadColor"
              details-td-class="m-0 p-0"
              small
              rounded
            >
              <template #cell(patient_data)="row">
                <div class="py-50">{{ row.item.patient_data.full_name }}</div>
              </template>
              <template #cell(amount)="row">
                <div class="py-50">${{ row.item.amount }}</div>
              </template>
              <template #cell(is_paid)="row">
                <div class="py-50">
                  <b-badge
                    v-if="row.item.is_paid"
                    pill
                    class="font-weight-bolder m-0"
                    variant="success"
                    style="font-size: 0.8rem"
                  >
                    Paid
                  </b-badge>
                  <b-badge
                    v-else
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    Pending
                  </b-badge>
                </div>
              </template>
              <template #cell(manage)="row">
                <div class="">
                  <b-button
                    pill
                    size="sm"
                    variant="info"
                    @click="viewPaidOrder(row.item)"
                  >
                    View Details
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              size="md"
              :total-rows="totalPaidItems"
              v-model="paidOrdersCurrentPage"
              :per-page="paidOrdersPerPage"
            ></b-pagination>
            <h6>
              <b>Total Rows: {{ totalPaidItems }}</b>
            </h6>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Doctor History
       <b-col md="4" class="">
         <b-row class="">
          <b-col md="12" class="">
            <b-card no-body>
              <b-card-header>
                <div>
                  <h4 class="font-weight-bolder text-dark m-0 p-0">
                    History of Doctors’ Visits
                  </h4>
                </div>
                <div>
                  <span
                    class="font-weight-bolder text-dark m-0 p-0 cursor-pointer text-colorGreen"
                  >
                    <u>View All</u>
                  </span>
                </div>
              </b-card-header>
              <b-card-body>
                <b-card no-body style="border-bottom: 2px solid lightgray">
                  <b-card-header>
                    <div>
                      <img
                        src="@/assets/images/logo/logo.png"
                        img-fluid
                        class="mx-1 cursor-pointer"
                        width="30px"
                        height="30px"
                      />
                    </div>
                    <div>
                      <h6 class="font-weight-bolder text-dark">
                        Muhammad Khan
                      </h6>
                      <p class="font-weight-normal text-dark m-0 p-0">
                        Cardiologist
                      </p>
                    </div>
                    <div>
                      <p>8 April, 2024 | 04:00 PM</p>
                    </div>
                  </b-card-header>
                </b-card>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row> 
         <b-row class="">
          <b-col md="12" class="">
            <b-card no-body>
              <b-card-header>
                <div>
                  <h4 class="font-weight-bolder text-dark m-0 p-0">Calender</h4>
                </div>
              </b-card-header>
              <b-card-body> </b-card-body>
            </b-card>
          </b-col>
        </b-row> 
      </b-col> -->
    </b-row>

    <pending-order-detail-modal
      :order="pendingOrder"
      @modalClosed="onPendingModalClosed"
      :key="`view-${PendingOrderDetailModal}`"
    />
    <paid-order-detail-modal
      :order="paidOrder"
      @modalClosed="onPaidModalClosed"
      :key="`view2-${PaidOrderDetailModal}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import PendingOrderDetailModal from "@/components/admin/patient/dashbaord/PendingOrderModal.vue";
import PaidOrderDetailModal from "@/components/admin/patient/dashbaord/PaidOrderModal.vue";

export default {
  components: {
    PendingOrderDetailModal,
    PaidOrderDetailModal,
  },
  data() {
    return {
      fieldsPendingOrders: [
        { key: "patient_data", label: "Patient" },
        { key: "created_at", label: "Date" },
        { key: "order_number", label: "Order Info" },
        { key: "Item", label: "Item", class: "fixed-width-label" },
        { key: "is_paid", label: "Status" },
        { key: "amount", label: "Total Cost" },
        { key: "manage", label: "" },
      ],
      fieldsPaidOrders: [
        { key: "patient_data", label: "Patient" },
        { key: "created_at", label: "Date" },
        { key: "order_number", label: "Invoice No." },
        { key: "Item", label: "Item", class: "fixed-width-label" },
        { key: "is_paid", label: "Status" },
        { key: "amount", label: "Order Total" },
        { key: "manage", label: "" },
      ],
      PendingOrders: [],
      userRole: null,
      PaidOrders: [],
      PendingOrderDetailModal: 0,
      PaidOrderDetailModal: 0,
      pendingOrder: null,
      paidOrder: null,

      pendingOrdersCurrentPage: 1,
      pendingOrdersPerPage: 0,
      totalPendingItems: 0,
      paidOrdersCurrentPage: 1,
      paidOrdersPerPage: 0,
      totalPaidItems: 0,
    };
  },

  mixins: [util],

  async mounted() {
    await this.fetchPendingOrders();
    await this.fetchPaidOrders();
  },

  methods: {
    ...mapActions({
      getOrders: "appData/getOrderList2",
      initiatePayment: "appData/initiatePayment",
      payment: "appData/payment",
    }),
    async fetchPendingOrders() {
      try {
        let tempParams = {
          pageNumber: this.pendingOrdersCurrentPage,
          status: "pending",
        };
        const res = await this.getOrders(tempParams);
        if (res.status === 200) {
          this.PendingOrders = res.data.results;
          this.PendingOrders = res.data.results.map((order) => ({
            ...order,
            Item: order.order_items.map((item) => item.service_name).join(", "),
          }));
          this.totalPendingItems = res.data.count;
          this.pendingOrdersPerPage = res.data.per_page;
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async fetchPaidOrders() {
      try {
        let tempParams = {
          pageNumber: this.paidOrdersCurrentPage,
          status: "paid",
        };
        const res = await this.getOrders(tempParams);
        if (res.status === 200) {
          this.PaidOrders = res.data.results;
          this.PaidOrders = res.data.results.map((order) => ({
            ...order,
            Item: order.order_items.map((item) => item.service_name).join(", "),
          }));
          this.totalPaidItems = res.data.count;
          this.paidOrdersPerPage = res.data.per_page;
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async Payment(invoice) {
      try {
        this.showLoader();
        console.log("invoice data: ", invoice);
        const response = await this.initiatePayment(
          {
            order_id: invoice.id,
            amount: invoice.amount,
            customer_name: invoice.patient_data.full_name,
            order_number: invoice.order_number,
          },
          {}
        );
        if (
          response.data.Transaction &&
          response.data.Transaction.PaymentPage
        ) {
          const res_data = response.data;
          const res = await this.payment({
            order: invoice.id,
            amount: invoice.amount,
            transaction_details: JSON.stringify(res_data),
            transaction_id: res_data.Transaction.TransactionID,
          });
          if (res.status === 200) {
            window.location.href = response.data.Transaction.PaymentPage;
          }
        } else {
          this.displayError("Payment initiation failed:", response.data);
        }
      } catch (error) {
        this.displayError("Error initiating payment:", error);
      } finally {
        this.hideLoader();
      }
    },

    showLoader() {
      document.getElementById("loading-bg").style.display = "block";
    },

    hideLoader() {
      document.getElementById("loading-bg").style.display = "none";
    },

    viewPendingOrder(pendingOrder) {
      this.pendingOrder = pendingOrder;
      this.PendingOrderDetailModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("pending-order-detail-modal");
      });
    },
    viewPaidOrder(paidOrder) {
      this.paidOrder = paidOrder;
      this.PaidOrderDetailModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("paid-order-detail-modal");
      });
    },

    onPendingModalClosed() {
      this.pendingOrder = null;
    },
    onPaidModalClosed() {
      this.paidOrder = null;
    },
  },

  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },

  watch: {
    pendingOrdersCurrentPage: async function (val) {
      await this.fetchPendingOrders();
    },
    paidOrdersCurrentPage: async function (val) {
      await this.fetchPaidOrders();
    },
  },
};
</script>

<style>
.fixed-width-label {
  width: 250px;
}
</style>

<!-- doctorDetails: [
{
  name: "Muhammad Khan",
  date: "8 April, 2024 | 04:00 PM",
  specialiality: "Cardiologist",
},        
], -->

<!-- fieldsPendingInvoice: [
        { key: "Date", label: "Date" },
        { key: "InvoiceNo", label: "Invoice no."},
        { key: "Item", label: "Item" },
        { key: "Status", label: "Status" },
        { key: "OrderTotal", label: "Order Total" },
        { key: "manage", label: "" },
      ],
      pendingInvoice: [
        {
          Date: " 11/04/2024",
          InvoiceNo: "1022021",
          Item: "Ambulance Service",
          Status: "Pending",
          OrderTotal: "RS 5000",
        },
        {
          Date: " 11/04/2024",
          InvoiceNo: "1022021",
          Item: "Ambulance Service",
          Status: "Pending",
          OrderTotal: "RS 3000",
        },
        {
          Date: " 11/04/2024",
          InvoiceNo: "1022021",
          Item: "Ambulance Service",
          Status: "Paid",
          OrderTotal: "RS 3000",
        },
      ], -->
